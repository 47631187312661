import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent {
  @Input() title: string = '';
  @Input() message: string = '';
  showModal: boolean = false;

  open(title: string, message: string) {
    this.title = title;
    this.message = message;
    this.showModal = true;
  }

  close() {
    this.showModal = false;
  }
}
