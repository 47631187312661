import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { ModalComponent } from '../modal/modal.component';
import { NgSelectComponent } from '@ng-select/ng-select';

@Component({
  selector: 'app-test-form',
  templateUrl: './test-form.component.html',
  styleUrls: ['./test-form.component.scss']
})
export class TestFormComponent implements OnInit {
  @ViewChild('modal', { static: false }) modal: ModalComponent;
  @ViewChild(NgSelectComponent, { static: false }) ngSelectComponent: NgSelectComponent;
  apiUrl = environment.apiUrl;
  envName = environment.envName;

  formData = {
    jira_ticket_id: '',
    qTest_project_id: '',
    qTest_module_id: '',
    qTest_case_name: ''
    // requirement_ids: ''
  };

  newConfiguration = {
    configuration_name: '',
    jira_base_url: '',
    jira_username: '',
    jira_api_token: '',
    qtest_base_url: '',
    qtest_api_token: ''
  };

  configurations = [];
  projectObject = [];
  moduleObject = [];
  selectedConfigurationName: string = null;
  selectedConfiguration: any;
  showAddConfigurationForm = false;
  placeHolder_configuration="Select a Configuration";
  placeHolder_project="Select a Project";
  placeHolder_module="Select a Module";
  HTMLContextValue = "Add";
  loading = false;
  isAcceptanceCriteriaEnabled = false;

  constructor(private http: HttpClient) {}

  ngOnInit() {
    this.loadConfigurations();
  }

  loadConfigurations() {
    const apiUrl = `${this.apiUrl}/get_configuration_names?env_name=${this.envName}`;
    this.http.get(apiUrl).subscribe((data: any) => {
      this.configurations = data.configurations;
    });
  }

  onConfigurationChange(event: any) {
    this.placeHolder_configuration = event!=undefined?'':"Select a Configuration"
    this.projectObject=[]
    this.moduleObject=[]
    this.formData.qTest_project_id=''
    this.formData.qTest_module_id=''
    this.showAddConfigurationForm = false;
    this.HTMLContextValue = "Add";
    if(event!=undefined){
      const configName = event.configuration_name;
      this.selectedConfiguration = this.configurations.find(config => config.configuration_name === configName);
      this.loadQTestProjectObjects(configName); 
    }// Call to load project names
  }

  // New method to load project names from the backend
  loadQTestProjectObjects(configName: string) {
    const apiUrl = `${this.apiUrl}/get_project_dictionary?env_name=${this.envName}&config_name=${configName}`;
    this.http.get(apiUrl).subscribe((data: any) => {
      this.projectObject = data;
    }, (error) => {
      this.showErrorAlert('Error loading project object: ' + error.message);
      console.error('Error loading project object:', error);
    });
  }

  loadQTestModuleObjects(configName: string, project_id){
    const apiUrl = `${this.apiUrl}/get_module_dictionary?env_name=${this.envName}&config_name=${configName}&project_id=${project_id}`;
    this.http.get(apiUrl).subscribe((data: any) => {
      this.moduleObject = data;
    }, (error) => {
      this.showErrorAlert('Error loading module object: ' + error.message);
      console.error('Error loading module object:', error);
    });
  }
  
  getKeyByValue(value: string, obj): string {
    return Object.keys(obj).find(key => obj[key] === value);
  }

  onProjectChange(event: any){
    this.moduleObject=[]
    this.placeHolder_project=event!=undefined?'':"Select a Project"
    if(event!=undefined){
      this.formData.qTest_project_id = event.id;
      this.loadQTestModuleObjects(this.selectedConfiguration.configuration_name, this.formData.qTest_project_id);
    }
  }

  onModuleChange(event: any){
    this.placeHolder_module = event!=undefined?'':"Select a Module"
    this.formData.qTest_module_id = event.id
  }

  toggleAddConfigurationForm() {
    this.ngSelectComponent.handleClearClick();
    this.selectedConfigurationName = null;
    this.selectedConfiguration = false;
    this.HTMLContextValue = this.HTMLContextValue === "Add" ? "Close" : "Add";
    this.showAddConfigurationForm = !this.showAddConfigurationForm;
    if (!this.showAddConfigurationForm) {
      this.resetNewConfiguration();
    }
  }

  toggleAcceptanceCriteria(){
    this.isAcceptanceCriteriaEnabled = !this.isAcceptanceCriteriaEnabled;
  }

  resetNewConfiguration() {
    this.newConfiguration = {
      configuration_name: '',
      jira_base_url: '',
      jira_username: '',
      jira_api_token: '',
      qtest_base_url: '',
      qtest_api_token: ''
    };
  }

  saveConfiguration() {
    if (this.isNewConfigurationValid()) {
      const apiUrl = `${this.apiUrl}/add_configuration?env_name=${this.envName}`;
      this.http.post(apiUrl, this.newConfiguration).subscribe((response: any) => {
        if (response.status === 'success') {
          this.handleSuccessResponse({"status": "success", "message":'Configuration saved successfully!'});
          this.loadConfigurations();
          this.showAddConfigurationForm = false;
          this.resetNewConfiguration();
        } else {
          this.handleSuccessResponse({"status": "failed", "message":'Error saving configuration: ' + response.message});
        }
      }, (error) => {
        this.handleSuccessResponse({"status": "failed", "message":'Error saving configuration: ' + error.message});
        console.error('Error saving configuration:', error);
      });
    } else {
      this.handleSuccessResponse({"status": "failed", "message":"Empty Input Field(s) Found"});
    }
  }

  isNewConfigurationValid() {
    return this.newConfiguration.configuration_name !== '' &&
           this.newConfiguration.jira_api_token !== '' &&
           this.newConfiguration.jira_base_url !== '' &&
           this.newConfiguration.jira_username !== '' &&
           this.newConfiguration.qtest_api_token !== '' &&
           this.newConfiguration.qtest_base_url !== '';
  }

  isFormDataValid() {
    return this.formData.jira_ticket_id !== '' && this.formData.qTest_project_id != '';
  }

  onSubmit(event: Event) {
    event.preventDefault();
    if (this.isFormDataValid()) {
      this.loading = true;
      const apiUrl = `${this.apiUrl}/jira_test_case_gen_api?env_name=${this.envName}`;
      const payload = {
        configuration_name: this.selectedConfiguration.configuration_name,
        jira_ticket_id: this.formData.jira_ticket_id,
        qTest_project_id: this.formData.qTest_project_id,
        qTest_module_id: this.formData.qTest_module_id ,
        qTest_case_name: this.formData.qTest_case_name,
        isAcceptanceCriteriaEnabled: this.isAcceptanceCriteriaEnabled
      };

      this.http.post(apiUrl, payload).subscribe((response: any) => {
        this.loading = false;
        this.handleSuccessResponse(response);
      }, (error) => {
        this.loading = false;
        this.handleSuccessResponse({'status':"failed", "message":'Error generating test case: ' + error.message});
        console.error('Error generating test case:', error);
      });
    } else {
      this.handleSuccessResponse({'status':"failed", "message":"Empty Input Field(s) Found"});
    }
  }

  handleSuccessResponse(response: any) {
    this.loading = false;

    if (Array.isArray(response)) {
      // Handle the response if it's an array of dictionaries
      // const messages = response.map(res => res.message).join('\n');
      this.showSuccessAlert(response);
    } else if (response && typeof response === 'object') {
      // Handle the response if it's a single dictionary
      if (response.status === 'success') {
        this.showSuccessAlert([response]);
      } else if (response.status === 'failed') {
        this.showErrorAlert([response]);
      } else {
        this.showErrorAlert([{'status':"failed", "message":'Unknown response status.'}]);
      }
    } else {
      this.showErrorAlert([{'status':"failed", "message":'Invalid response format.'}]);
    }
  }

  private showSuccessAlert(message: any) {
    this.modal.open('Success', message);
  }

  private showErrorAlert(message: any) {
    this.modal.open('Error', message);
  }
}
